import React from "react"
import styled from "@emotion/styled"
import ReactHtmlParser from "react-html-parser"
import { colors } from "../../styles/colors"
import { fontFamily } from "../../styles/fonts/fontFamily"
import AppWrapper from "../../styles/AppWrapper"

const ContactInfo = ({ contactParagraph }) => {
    return (
        <>
            <AppWrapper>
                <ContactSection>
                    <ParagraphDiv>
                        {ReactHtmlParser(contactParagraph)}
                    </ParagraphDiv>
                </ContactSection>
            </AppWrapper>
        </>
    )
}
export default ContactInfo

const ParagraphDiv = styled.div`
    p {
        font-size: 18px;
        color: ${props => props.textColor || colors.black};
        line-height: 1.8;
        z-index: 9;
        font-family: ${fontFamily.manrope};
        font-weight: 500;
        max-width: 700px;
        padding: 15px 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
        color: ${colors.purple};
    }
`

const ContactSection = styled.section`
    margin-bottom: 150px;
`
