import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/Heading"
import ContactInfo from "../components/Contact/ContactInfo"

const ContactPage = ({ data }) => {
    const { heading, info } = get(data, "allContactJson.edges[0].node", {})
    const { copy: headingCopy } = heading
    const { copy: contactInfo } = info

    return (
        <Layout>
            <SEO
                title="Say Hello | Johnnie Aiello"
                description="Reach out to Johnnie Aiello if you have a project, RFP, speaking engagement,teaching assignment/lecture, or just want top say hello."
            />
            <Heading headingText={headingCopy.header} />
            <ContactInfo contactParagraph={contactInfo.paragraph} />
        </Layout>
    )
}

export default ContactPage

export const query = graphql`
    query {
        allContactJson {
            edges {
                node {
                    heading {
                        copy {
                            header
                        }
                    }
                    info {
                        copy {
                            paragraph
                        }
                    }
                }
            }
        }
    }
`
